export const singleVacaturesData = {
    init() {
        // JavaScript to be fired on all pages
        const $accordionTitle = $('.js-vacancy-accordion-title');

        $accordionTitle.on('click', function (e) {
            e.preventDefault();
            const id = $(this).data('title-id');

            $(this).toggleClass('is-active');
            $('[data-content-id="' + id + '"]').slideToggle(300);
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
