export default {
    init() {
        // JavaScript to be fired on the home page
        $(window).on('load', function () {
            $('.js-usp-slider').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                draggable: true,
                arrows: false,
                dots: false,
                fade: false,
                speed: 500,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 1240,
                        settings: {
                            slidesToShow: 3,
                        },
                },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                        },
                },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                },
                ],
            });

            if ($(window).width() <= 991) {
                $('.js-facts-slider').slick({
                    autoplay: false,
                    autoplaySpeed: 5000,
                    draggable: true,
                    arrow: true,
                    prevArrow: $('.js-facts-nav-prev'),
                    nextArrow: $('.js-facts-nav-next'),
                    dots: false,
                    speed: 500,
                    infinite: true,
                    slidesToScroll: 1,
                    variableWidth: true,
                    centerMode: true,
                    responsive: [
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: 1,
                                variableWidth: false,
                            },
                    },
                    ],
                });
            }

                $('.js-team-slider').slick({
                    autoplay: false,
                    autoplaySpeed: 5000,
                    draggable: true,
                    arrow: true,
                    prevArrow: $('.js-team-nav-prev'),
                    nextArrow: $('.js-team-nav-next'),
                    dots: false,
                    fade: false,
                    speed: 500,
                    infinite: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    responsive: [
                        {
                            breakpoint: 1120,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            },
                    },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            },
                    },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                    },
                    ],
                }).on('init', function (slick) {
                    console.log('PUSSY!');
                    console.log({slick});
                }).css('opacity', 1);
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
