export const templateFaqData = {
    init() {
        // JavaScript to be fired on all pages
        $('.faq__filter .searchandfilter > ul > li li').addClass('checkcontainer').append('<span class="checkmark"></span>');

        if ($(window).width() < 992) {
            $('.faq__filter__title').click(function () {
                $('.faq__filter').toggleClass('faq__filter__open');
            });
        }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
