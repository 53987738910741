import {WOW} from 'wowjs';

export default {
    init() {
        // JavaScript to be fired on all pages
        let wow;

        initWOW();

        function initWOW() {
            if ($(window).width() < 767) {
                wow = new WOW({
                    offset: 100,
                    live: true,
                });
            } else {
                wow = new WOW({
                    offset: 250,
                    live: true,
                });
            }

            wow.init();
        }

        $(document).on('sf:ajaxfinish', '.searchandfilter', function(){
            $('.faq__category .wow').removeClass('wow');
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.content__title').on('click', function () {
            $(this).parent().toggleClass('open')
            $(this).parent().find('.content__list').slideToggle()
        })

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
