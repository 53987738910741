$(document).ready(function() {

    // Slick slider banner
    $('.banner__slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: true,
        arrows: false,
        dots: true,
        fade: true,
        speed: 900,
        infinite: true,
    });

});
